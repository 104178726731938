////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {UsageDataItem} from "../../dataModel/UsageDataItem";
import {UsageSummaryUI} from "../../dataModel/UsageSummaryUI";
import {JobRunItemStatusType, JobRunStatusType} from "../../clients/Classes";

export class UsageState {
  public dataItems: UsageDataItem[];
  public customerIds: string[];
  public userIds: string[];
  public jobIds: string[];
  public statuses: JobRunStatusType[];
  public startDate: Date | null = null;
  public useStartDate: boolean;
  public endDate: Date | null = null;
  public useEndDate: boolean;
  public loading = false;
  public result: UsageSummaryUI | undefined;
  public loadingMoreData = false;
  public hasMoreData = false;

  constructor() {
    this.statuses = [];
    this.jobIds = [];
    this.customerIds = [];
    this.userIds = [];
    this.dataItems = [];
    this.useStartDate = false;
    this.useEndDate = false;
  }
}
