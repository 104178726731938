// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

export enum PageTypes {
  protected,
  auth,
  customers,
  usage,
  settings,
}

export type TaskTrigger =
  'OnceNow' |
  'OnceLater' |
  'Recurring' |
  'OnPublish';

export type RecurrencePeriod =
  'Daily' |
  'Weekly' |
  'Monthly';

export type RecurrenceEndType =
  'Date' |
  'Occurrences' |
  'None';

export type RecurrenceDayTypes =
  'Numbered' |
  'Weekday';

export type RecurrenceMonthTypes =
  'NumberedDay' |
  'Weekday';

export type Days =
  'Sunday' |
  'Monday' |
  'Tuesday' |
  'Wednesday' |
  'Thursday' |
  'Friday' |
  'Saturday';

export type TutorialType = 'tasks' | 'reports' | 'directDownload';

export type PaginationPages =
  'reports'
  | 'webhooks'
  | 'customers'
  | 'jobs'
  | 'tasks'
  | 'usage'
  | 'projectWiseCredentials'
  | 'projectWiseConfigurations'
  | 'projectWiseRepositories';

export type ProjectWiseActionTypes = 'edit' | 'delete' | 'duplicate';

export enum InfoMenuActions {
  "multipleActions",
  "modalOpen",
  "modalContent",
}

export enum TasksActions {
  "multipleActions",
  "tasks",
  "filterOpen",
  "filteredTasks",
  "loading",
  'loadingMoreData',
  'hasMoreData',
  'canCancelLoad'
}

export enum ReportsActions {
  "multipleActions",
  "reports",
  "filterOpen",
  "filterOptions",
  "filteredReports",
  "loading",
  'checkedReports',
  'globalCheck',
  'loadingMoreData',
  'hasMoreData',
  'canCancelLoad'
}

export enum CustomersActions {
  "multipleActions",
  "customers",
  "editingCustomer",
  "showEdit",
  "showDelete",
  "editHeader",
  "loading",
  'filteredCustomers',
  'loadingMoreData',
  'hasMoreData',
  'canCancelLoad'
}

export enum JobsActions {
  "multipleActions",
  "dataItems",
  "customerIds",
  "userIds",
  "jobIds",
  "statuses",
  "startDate",
  "useStartDate",
  "endDate",
  "useEndDate",
  "sortingField",
  "sortAscending",
  "loading",
  "loadingMoreData",
  "loadingCsv",
  "hasMoreData",
  "showDetail",
  "detailObject",
  'truncateDetailStrings'
}

export enum UsageActions {
  "multipleActions",
  "dataItems",
  "customerIds",
  "userIds",
  "jobIds",
  "statuses",
  "startDate",
  "useStartDate",
  "endDate",
  "useEndDate",
  "loading",
  "result",
  'loadingMoreData',
  'hasMoreData'
}

export enum TaskEditActions {
  "multipleActions",
  "task",
  "originalTask",
  "loading",
  "new",
  "duplicating",
  "selectedTab",
  "projects",
  "loadingProjects",
  "Saving",
  "showCancelConfirm",
  "expandedSource",
  "expandedDestination",
  'selectedProjectSource',
  'selectedProjectDestination',
  'projectWiseConfigs',
  'loadingProjectWiseConfigs',
  'selectedProjectWiseConfigSource',
  'selectedProjectWiseConfigDestination',
  'selectedTabSource',
  'selectedTabDestination'
}

export enum BulkTaskEditActions {
  'multipleActions',
  'projects',
  'task',
  'originalTask',
  'loading',
  'loadingProjects',
  'saving',
  'isNewTask',
  'isDuplicating',
  'showCancelConfirm',
  'selectedTab',
  'selectedProjectDestination',
  'expandedDestination'
}

export enum DirectDownloadActions {
  "multipleActions",
  "projects",
  "selectedProject",
  "loadingProjects",
  'projectWiseConfigs',
  'selectedProjectWiseConfig',
  'loadingProjectWiseConfigs',
  'selectedTab'
}

export enum WebhookSettingsActions {
  'multipleActions',
  'loading',
  'webHooks',
  'editingHook',
  'showEdit',
  'showDelete',
  'showDeleteAll',
  'showResponseDetail',
  'showSampleBody',
  'sampleBody',
  'responseDetail',
  'editIsNew',
  'editName',
  'editUrl',
  'editSecret',
  'editEnabled',
  'loadingMoreWebhooks',
  'hasMoreWebhooks',
  'canCancelLoad',
}

export enum ProjectWiseSettingsActions {
  'multipleActions',
  'loadingCredentials',
  'loadingConfigurations',
  'hasMoreCredentials',
  'hasMoreConfigurations',
  'hasMoreRepositories',
  'canCancelCredentials',
  'canCancelConfigurations',
  'credentials',
  'configurations',
  'editingCredential',
  'editingConfiguration',
  'isEditingCredential',
  'isEditingConfiguration',
  'editPassword',
  'editIsNew',
  'credentialSelectOptions',
  'selectedCredential',
  'selectedTabRepository',
  'repositories',
  'selectedRepository',
  'loadingRepositories'
}

export enum FolderTools {
  CheckSingle,
  CheckRecursive,
  UncheckSingle,
  UncheckRecursive
}
