////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {BulkTaskUI} from "../dataModel/BulkTaskUI";
import {ProjectUI} from "../dataModel/ProjectUI";
import {FileService} from "../services/FileService";
import {FindProjectItemRecursive, GetErrorMessage, GetRecursiveFilePath} from "../Utility";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {CenteringContainer, LineWrapper, ValueWrapper} from "../CommonStyledComponents";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Theme from "@adsk/alloy-react-theme";
import {ConvertAutodeskSchedule} from "../converters/ConvertAutodeskSchedule";

const fileService = new FileService();

const BulkSettingsSummary = ({task, projects}: { task: BulkTaskUI | undefined, projects: ProjectUI[] }) => {
  const [destinationPath, setDestinationPath] = useState<string>('');
  const [loadingPaths, setLoadingPaths] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (task?.DestinationDirectory?.Id == null) {
      return;
    }

    setLoadingPaths(true);

    const project = projects.find(p => p.Id === task.DestinationDirectory?.ProjectId);
    if (project == null) {
      alert('Destination project was not found');
      return;
    }

    fileService.PopulateToDirectories(project, [task.DestinationDirectory?.Id])
      .then(results => {
        if (!isMounted) {
          return;
        }

        if (results.find(r => !r.Success) != null) {
          alert('There were errors loading paths, some paths may not display correctly');
        }

        results.forEach(r => {
          if (task.DestinationDirectory != null) {
            const loaded = r.LoadedDirectories.find(l => l.Id === task.DestinationDirectory?.Id);
            if (loaded != null) {
              task.DestinationDirectory.Name = loaded.Name;
            }
          }
        });

        const populatedDirectory = project == null || task.DestinationDirectory == null
          ? undefined
          : FindProjectItemRecursive(project.RootFolderArray, task.DestinationDirectory?.Id) as DirectoryUI;

        task.DestinationDirectory = populatedDirectory;
        const destinationPathOnly = populatedDirectory == null
          ? ''
          : GetRecursiveFilePath(populatedDirectory, '/');
        setDestinationPath(`${project?.Name}/${destinationPathOnly}`);

        setLoadingPaths(false);
      })
      .catch(e => {
        alert(GetErrorMessage(e, 'Get file paths'));
        setLoadingPaths(false);
        if (task.DestinationDirectory != null) {
          setDestinationPath(`[Error]/${task.DestinationDirectory.Name}`)
        }
      });

    return () => {
      isMounted = false;
    }
  }, []);

  return (
    <>
      {(task == null || projects == null || projects.length <= 0) && (
        <CenteringContainer style={{flex: 1}}>
          <ProgressRing size={'large'}/>
        </CenteringContainer>
      )}
      {task != null && projects != null && projects.length > 0 && (
        <div>
          <h2 style={Theme.typography.heading2}>Summary</h2>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Task Name</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>{task?.Name}</LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Source Projects</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>
              {task.Projects.map(p => <LineWrapper key={p.Id}>{`${p.HubName}: ${p.Name}`}</LineWrapper>)}
            </LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Destination Folder</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>
              {loadingPaths && <ProgressRing/>}
              {!loadingPaths && destinationPath}
            </LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Trigger</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>{task?.Trigger}</LineWrapper>
            {
              task?.Trigger === 'OnceLater' &&
              <LineWrapper style={Theme.typography.bodyMedium}>{task?.StartTime?.toString()}</LineWrapper>
            }
          </ValueWrapper>
        </div>
      )}
    </>
  );
};

export default BulkSettingsSummary;